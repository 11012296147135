import { get, download } from './api';

export const getEnvironmentNewestLicense = async (environmentId) => {
  return await get(`Licences/GetEnvironmentLicence/${environmentId}`);
};

export const downloadCurrentLicense = async (environmentId) => {
  return await download(`Licences/DownloadEnvironmentLicence/${environmentId}`);
};

export const renewLicense = async (environmentId) => {
  return await get(`Licences/RenewEnvironmentLicence/${environmentId}`);
};

