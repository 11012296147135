import React, { useState, useEffect } from 'react';
import { getCustomersStatistics } from '../../../../api/environmentsApi';
import { stringToLocaleDateString } from '../../../../utils/date';
import { UsersIcon, OfficeBuildingIcon, CalendarIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { useToastAction } from '@metaforcelabs/metaforce-core';

const EnvironmentStatistics = ({ environment }) => {
  const [statistics, setStatistics] = useState(null);
  const fetchStatisticsAction = useToastAction();

  const fetchStatistics = () => {
    fetchStatisticsAction.execute(async () => {
      const result = await getCustomersStatistics(environment.id);
      setStatistics(result);
    }, 'Failed to load data');
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <>
        <div className="max-w-screen-2xl">
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                <div className="p-5 bg-white rounded shadow-sm">
                    <div className="flex items-center space-x-4">
                        <div>
                            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-fuchsia-50 text-fuchsia-400">
                                <OfficeBuildingIcon className="w-8 h-8" />
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-400">Numer of customers</div>
                            <div className="text-2xl font-bold text-gray-900">{statistics?.numbeOfCustomers}</div>
                        </div>
                    </div>
                </div>
                <div className="p-5 bg-white rounded shadow-sm">
                    <div className="flex items-center space-x-4">
                        <div>
                            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-cyan-50 text-cyan-400">
                                <UsersIcon className="w-8 h-8" />
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-400">Numer of users</div>
                            <div className="text-2xl font-bold text-gray-900">{statistics?.numbeOfUsers}</div>
                        </div>
                    </div>
                </div>
                <div className="p-5 bg-white rounded shadow-sm">
                    <div className="flex items-center space-x-4">
                        <div>
                            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-amber-50 text-amber-400">
                                <CalendarIcon className="w-8 h-8" />
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-400">Date Add</div>
                            <div className="text-2xl font-bold text-gray-900">{stringToLocaleDateString(environment.createdDate)}</div>
                        </div>
                    </div>
                </div>
                <div className="p-5 bg-white rounded shadow-sm">
                    <div className="flex items-center space-x-4">
                        <div>
                            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-emerald-50 text-emerald-400">
                                <InformationCircleIcon className="w-8 h-8" />
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-400">License Due</div>
                            <div className="text-2xl font-bold text-gray-900">{stringToLocaleDateString(statistics?.licenseDueDate)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default EnvironmentStatistics;
