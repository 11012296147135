import { get, post } from './api';

export const getEnvironmentCustomers = async () => {
  return await get(`Environments/GetEnvironments`);
};

export const getEnvironment = async (environmentId) => {
  return await get(`Environments/GetEnvironment/${environmentId}`);
};

export const saveEnvironment = async (environment) => {
  return await post(`Environments/SaveEnvironment`,environment);
}

export const getCustomersStatistics = async (environmentId) => {
  return await get(`Statictics/GetEnvironmentCustomers/${environmentId}`);
}

export const getCustomerLicences = async (environmentId) => {
  return await get(`Statictics/GetCustomerLicences/${environmentId}`);
}
