import React, { Fragment, useState, useEffect } from 'react';
import { Badge } from '../../../../components';
import { useToastAction, useTableActions } from '@metaforcelabs/metaforce-core';
import { stringToLocaleDateTimeString } from '../../../../utils/date';
import { getCustomerLicences } from '../../../../api/environmentsApi';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../../components/Table';
import { Table as TableCore } from '@metaforcelabs/metaforce-core';

const EnvironmentCustomerLicences = ({ environment }) => {
  const [customersWithLicences, setCustomersWithLicences] = useState([]);
  const [tireNames, setTireNames] = useState({});
  const fetchAction = useToastAction();
  const tableActions = useTableActions(customersWithLicences, 5);

  const fetchData = () => {
    fetchAction.execute(async () => {
      const result = await getCustomerLicences(environment.id);
      setCustomersWithLicences(result.customerLicences
        .sort((a, b) => a.name.localeCompare(b.name)));
      setTireNames(result.tiresNames);
    }, 'Failed to load data');
  };

  const subscriptionModelBadge = (status) => {
    switch (status) {
      case 0:
        return <Badge type="warn" text="Monthly" />;
      case 1:
      default:
        return <Badge type="info" text="Monthly per user" />;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

    return (
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <Table>
                <TableHead>
                  <TableHeader>Company Details</TableHeader>
                  <TableHeader>Current Licence Tire</TableHeader>
                  <TableHeader>Subscription model</TableHeader>
                  <TableHeader>Created</TableHeader>
                  <TableHeader>License due</TableHeader>
                </TableHead>
                <TableBody>
                  {tableActions.pageData?.map((row, index) => (
                    <Fragment key={index}>
                      <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                        <TableColumn>
                          <div className="flex items-center">
                            <div className="">
                              <div className="font-medium text-gray-900">{row.name}</div>
                              <div className="text-gray-500">{row.orgNumber}</div>
                            </div>
                          </div>
                        </TableColumn>
                        <TableColumn>
                          <div className="text-gray-900">{tireNames[row.currentLicence?.tierLevel]}</div>
                          <div className="text-gray-500">(Tire level: {row.currentLicence?.tierLevel})</div>
                        </TableColumn>
                        <TableColumn>
                          {subscriptionModelBadge(row.currentLicence?.subscriptionModel)}
                        </TableColumn>
                        <TableColumn>
                          {stringToLocaleDateTimeString(row.currentLicence?.subscriptionDate)}
                        </TableColumn>
                        <TableColumn>{row.currentLicence?.expirationDate === null ? 'No due date' : stringToLocaleDateTimeString(row.currentLicence?.expirationDate)}</TableColumn>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
              <TableCore.Pagination tableActions={tableActions} />
          </div>
        </div>
      </div>
  );
};

export default EnvironmentCustomerLicences;
