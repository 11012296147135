import React, { useEffect, useState } from 'react';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { getEnvironment } from '../../../api/environmentsApi';
import { initTabs } from './consts/EnvironmentDetails';
import { useClassNames } from '../../../hooks/useClassNames';
import EnvironmentStatistics from './components/EnvironmentStatistics';

const EnvironmentDetails = ({ match }) => {
  const [environment, setEnvironment] = useState(null);
  const [tabs, setTabs] = useState(initTabs);
  const { classNames } = useClassNames();
  
  const fetchEnvironmentAction = useToastAction();

  const fetchEnvironment = () => {
    fetchEnvironmentAction.execute(async () => {
      const result = await getEnvironment(match.params.id);
      setEnvironment(result);
    }, 'Failed to load data');
  };

  const handleOnTabItemClick = (tab, e) => {
    e.preventDefault();

    setTabs((state) =>
      state.map((s) => {
        if (s.name === tab.name) {
          s.current = true;
        } else {
          s.current = false;
        }

        return s;
      })
    );
  };

  const getTabComponent = () => {
    var current = tabs.find((tab) => tab.current);
    if(current.component){
      const Component = current.component;
      return <Component environment={environment}/>
    }
    return <div/>;
  }
  // const handleOnExternalProviderCheckClick = (values) => {
  //   externalProviderDataAction.execute(async () => {
  //     const externalProviderCustomerData = await getExternalProviderCustomerData(values.orgNumber);

  //     if (externalProviderCustomerData.type === responseTypes.success) {
  //       setFormValues(values);
  //       setExternalProviderCompanyData(JSON.parse(externalProviderCustomerData.data));
  //       setShowModal(true);
  //     } else {
  //       toast.error('There are not data found for provided organization number ');
  //     }
  //   }, 'Failed to data');
  // };

  // const getStreetAddress = (address) => {
  //   if (address?.length > 0) {
  //     if (address[0].includes('c/o') && address[1]) {
  //       return address[1];
  //     } else {
  //       return address[0];
  //     }
  //   }
  // };

  // const fulFillFromExternalProvider = (values) => {
  //   externalProviderFulFillAction.execute(async () => {
  //     const getBrregFulfillResponse = await getBrregFulfill(values.orgNumber);

  //     if (getBrregFulfillResponse.type === responseTypes.success) {
  //       setFormValues({
  //         ...values,
  //         name: getBrregFulfillResponse.data.name,
  //         billingAddress: {
  //           ...formValues.billingAddress,
  //           ...getBrregFulfillResponse.data?.registrationAddress,
  //           region: getBrregFulfillResponse.data?.registrationAddress?.state,
  //           streetName: getStreetAddress(getBrregFulfillResponse.data?.registrationAddress?.address)
  //         },
  //         visitingAddress: {
  //           ...formValues.visitingAddress,
  //           ...getBrregFulfillResponse.data?.officeAddress,
  //           region: getBrregFulfillResponse.data.officeAddress?.state,
  //           streetName: getStreetAddress(getBrregFulfillResponse.data?.officeAddress?.address)
  //         }
  //       });
  //     } else {
  //       toast.error('Failed to load data');
  //     }
  //     setShowModal(false);
  //   }, 'Failed to load data');
  // };

  useEffect(() => {
    fetchEnvironment();
  }, []);

  return (
    <>
      <div className="space-y-8">
        <div className="flex flex-col pb-5 mb-1 border-b border-gray-200 divide-y divide-gray-200">
          <h1 className="text-3xl font-bold">Environment - {environment?.name}</h1>
        </div>

        {environment && (
          <>
          <EnvironmentStatistics environment={environment}/>
          <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
            <nav className="-mb-px flex space-x-8 border-b border-gray-200">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={(e) => handleOnTabItemClick(tab, e)}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
            {getTabComponent()}
          </div>
          </>
        )}
      </div>
    </>
  );
};

export default EnvironmentDetails;
