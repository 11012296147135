import { Formik, Form } from 'formik';
import DateField from '../../../../components/DateField';
import { formInitialValues } from '../consts/Reports';

export default function DatesFromToFilter({ onUpdate }) {

  return (
    <div className='mt-6 mb-2'>
      <Formik
        initialValues={formInitialValues}
      >
        {(props) => (
          <Form>
            <div className="w-full mt-5 mb-8 pb-5">
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-4 lg:grid-cols-6">
                <div className="col-span-1 sm:col-span-1">
                  <DateField
                    navbar={true}
                    label="Date from"
                    name="dateFrom"
                    value={props.values.dateFrom}
                    onChange={(v, e) => {
                      props.setFieldValue('dateFrom', v);
                      onUpdate({
                        ...props.values,
                        dateFrom: v
                      });
                    }}
                  />
                </div>

                <div className="col-span-1 sm:col-span-1">
                  <DateField
                    navbar={true}
                    label={"Date to"}
                    name="dateTo"
                    value={props.values.dateTo}
                    onChange={(v, e) => {
                      props.setFieldValue('dateTo', v);
                      onUpdate({
                        ...props.values,
                        dateTo: v
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
