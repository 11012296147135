import { Fragment, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { Table as TableCore } from '@metaforcelabs/metaforce-core';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { getEnvironmentCustomers } from '../../../api/environmentsApi';
import { Link } from 'react-router-dom';
import { useTableActions } from '@metaforcelabs/metaforce-core';

const Environments = () => {
  const [recordsToShow, setRecordsToShow] = useState([]);
  const fetchEnvironmentsAction = useToastAction();
  const tableActions = useTableActions(recordsToShow, 10);

  const fetchEnvironments = () => {
    fetchEnvironmentsAction.execute(async () => {
      const result = await getEnvironmentCustomers();
      setRecordsToShow(result.sort((a, b) => a.name.localeCompare(b.name)));
    }, 'Failed to load environments');
  };

  useEffect(() => {
     fetchEnvironments();
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
          <h1 className="text-3xl font-bold">List of Pingdox Environments</h1>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            Below you will find a list of all existing pingdox environments
          </p>
        </div>

        <div className="relative mt-8">
          <Table>
            <TableHead>
              <TableHeader>Name</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Created</TableHeader>
            </TableHead>
            <TableBody>
              {tableActions.pageData?.map((row, index) => (
                <Fragment key={index}>
                  <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <TableColumn>
                      <Link
                        to={`/environments/${row.id}`}
                        className="font-medium text-sm text-indigo-600 hover:text-indigo-500 border-0 shadow-none"
                      >
                        {row.name}
                      </Link>
                    </TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn>
                      {stringToLocaleDateTimeString(row.createdDate)}
                    </TableColumn>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
          <TableCore.Pagination tableActions={tableActions} />
        </div>
      </div>
    </>
  );
};

export default Environments;
