import ManageEnvironment from '../components/ManageEnvironment';
import EnvironmentCustomerLicences from '../components/EnvironmentCustomerLicences';
import EnvironmentReports from '../components/EnvironmentReports';
import EnvironmentLicences from '../components/EnvironmentLicences';

export const initTabs = [
  {
    name: 'Details',
    current: true,
    component: ManageEnvironment,  
  },
  {
    name: 'Customers Licences',
    current: false,
    component: EnvironmentCustomerLicences
  },
  {
    name: 'Reports',
    current: false,
    component: EnvironmentReports
  },
  {
    name: 'Licence details',
    current: false,
    component: EnvironmentLicences
  }
];
