export const config = {
  // apiBaseUrl: 'https://localhost:5001',
  //apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  //apiBaseUrl: 'https://api.dialog.stage.metaforcelabs.com',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  tokenStorageKey: '@MetaForce:userToken',
  identityBaseUrl: process.env.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: process.env.REACT_APP_CENTERPOINT_BASE_URL,
  centerpointUiBaseUrl: process.env.REACT_APP_CENTERPOINT_UI_BASE_URL,
  productLicense: '611b8ac465064876fb35e1cf',
}
export const oidcConfig = {
    client_id: 'pingdoxadmin_web',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: 'code',
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid profile email api.internal offline_access',
    authority: process.env.REACT_APP_AUTHORITY,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true,
  };