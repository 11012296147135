import DateTimeParser from '../../../../utils/DateTimeParser';

export const ReportType = {
    CustomerLicences: 0,
    Transactions: 1
};
  
export const formInitialValues = {
    dateFrom: DateTimeParser.subtractYears(1),
    dateTo: DateTimeParser.toLocaleDateString(new Date())
};