import React, { Fragment, useState, useEffect } from 'react';
import { getEnvironmentNewestLicense, downloadCurrentLicense, renewLicense } from '../../../../api/licensingApi';
import { stringToLocaleDateTimeString } from '../../../../utils/date';
import { Link } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableColumn,
    TableHead,
    TableHeader,
    TableRow
  } from '../../../../components/Table';
  import { Table as TableCore } from '@metaforcelabs/metaforce-core';
import { useToastAction, useTableActions } from '@metaforcelabs/metaforce-core';

const EnvironmentLicences = ({ environment }) => {
    const [license, setLicense] = useState({});
    const fetchDataAction = useToastAction();
    const renewDataAction = useToastAction();

    const fetchData = () => {
        fetchDataAction.execute(async () => {
        const result = await getEnvironmentNewestLicense(environment.id);
        setLicense(result);
        }, 'Failed to load data');
    };

    const renewLicenseAction = () => {
        renewDataAction.execute(async () => {
            const result = await renewLicense(environment.id);
            setLicense(result);
        }, 'Failed to load data', 'License renewed');
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="mt-2 py-5">
            {
                license?.validLicense === false &&
                <div class="bg-blue-100 border-t-4 border-blue-500 rounded-b text-teal-900 px-4 py-3 mb-6 shadow-md" role="alert">
                    <div class="flex">
                        <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                        <div>
                            <p class="font-bold">Environment without license</p>
                            <p class="text-sm">Selected environment does not contain valid license. Please renew license to create a new one.</p>
                        </div>
                    </div>
                </div>          
            }
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Environment Id</dt>
                    <dd className="mt-1 text-sm text-gray-900">{license?.environmentId}</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">License creation date</dt>
                    <dd className="mt-1 text-sm text-gray-900">{stringToLocaleDateTimeString(license?.subscriptionDate)}</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Licence Valid To Date</dt>
                    <dd className="mt-1 text-sm text-gray-900">{stringToLocaleDateTimeString(license?.expirationDate)}</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">License grace date</dt>
                    <dd className="mt-1 text-sm text-gray-900">{stringToLocaleDateTimeString(license?.graceDate)}</dd>
                </div>
                <div className="sm:col-span-2">
                    <div className="mt-4 flex-1 flex justify-between sm:justify-end">
                        {license?.validLicense &&
                        <button onClick={() => downloadCurrentLicense(environment.id)} 
                            type="button" 
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2">
                                Download Current Licence file
                        </button> }
                        <button onClick={renewLicenseAction} 
                            type="button" 
                            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm">
                                Renew license
                        </button>
                    </div>
                </div>
            </dl>
        </div>
    );
};

export default EnvironmentLicences;
