import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {
  AuthenticationProvider,
  oidcLog,
  WebStorageStateStore,
  useReactOidc
} from '@axa-fr/react-oidc-context';
import AdminLayout from './layouts/AdminLayout';
import { Unauthorized } from './screens/Errors/Unauthorized';
import Oidc from './screens/Oidc';
import { OidcRoutesContext } from './contexts';
import { oidcConfig } from './utils/config';
import { NotFound } from './screens/Errors/NotFound';
import MissingLicense from './screens/Errors/MissingLicense';
import { Toaster } from './toaster';
import React from 'react';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { getUserProfile } from './api/userProfile';
import { getCustomerInfo } from './api/customer';
import { Environments, EnvironmentDetails } from './screens/Admin';

function App() {
  return (
    <div className="app min-h-screen">
      <Router>
        <AuthenticationProvider
          configuration={oidcConfig}
          loggerLevel={oidcLog.ERROR}
          isEnabled={true}
          UserStore={WebStorageStateStore}
          callbackComponentOverride={Oidc}
          notAuthenticated={() => {
            return <h1>Not Authenticated.</h1>;
          }}
          notAuthorized={() => {
            return <h1>Not Authorized.</h1>;
          }}
          authenticating={Oidc}
        >
          <Switch>
            <Route exact={true} path="/unauthorized" component={Unauthorized} />
            <Route exact={true} path="/missing-license" component={MissingLicense} />
            <Route exact={true} path="/not-found" component={NotFound} />

            <AdminLayoutRoute
              exact={true}
              path="/"
              nav="environments"
              component={Environments}
            />

            <AdminLayoutRoute
              exact={true}
              path="/environments/:id"
              nav="environmentDetails"
              component={EnvironmentDetails}
              featureEnabled={true}
            />

            {/* <OidcSecure>
              <SetAuthToken> */}
                <SetOidcRoutesContext> 
                  {/* <AdminLayoutRoute
                    exact={true}
                    path="/"
                    nav="environments"
                    component={Environments}
                  /> */}
{/* 
                  <AdminLayoutRoute
                    exact={true}
                    path="/admin/dashboard"
                    nav="dashboard"
                    component={Dashboard}
                    featureEnabled={true}
                  />

                  <AdminLayoutRoute
                    exact={true}
                    path="/admin/license"
                    nav="license"
                    component={License}
                    featureEnabled={true}
                  />

                  <AdminLayoutRoute
                    exact={true}
                    path="/admin/approvals"
                    nav="approvals"
                    component={Approvals}
                    featureEnabled={true}
                  />

                  

                  <AdminLayoutRoute
                    exact={true}
                    path="/admin/settings"
                    nav="settings"
                    component={Settings}
                    featureEnabled={true}
                  /> */}
                </SetOidcRoutesContext>
              {/* </SetAuthToken>
            </OidcSecure> */}
          </Switch>
        </AuthenticationProvider>
      </Router>
      <Toaster />
    </div>
  );
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AdminLayout nav={nav} subMenuNav={subMenuNav} {...props}>
          <Component {...props} />
        </AdminLayout>
      )}
      />
  );
}

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);

  const loadUserContextData = async () => {
    // loadAction.execute(async () => {
    //   const userProfilePromise = getUserProfile();
    //   const customerPromise = getCustomerInfo();
    //   const res = await Promise.all([userProfilePromise, customerPromise]);
    //   setUserProfile(res[0]);
    //   setCustomerInfo(res[1]);
    // }, 'Failed to load user data');
  };

  useEffect(() => {
    loadUserContextData();
  }, []);

  return (
    !loadAction.isExecuting &&
    userProfile &&
    customerInfo && (
      <OidcRoutesContext.Provider
        value={{
          userProfile,
          customerInfo
        }}
      >
        {children}
      </OidcRoutesContext.Provider>
    )
  );
};

// /**
//  * Small route wrapper that gets the oidc user and sets the axios auth header
//  */
// const SetAuthToken = ({ children }) => {
//   const { oidcUser, events } = useReactOidc();

//   setAuthHeader(oidcUser.access_token);

//   useEffect(() => {
//     if (!events) return;
//     events.addUserSignedOut(addUserSignedOut);
//     return () => {
//       events.removeUserSignedOut(addUserSignedOut);
//     };
//   }, [events]);

//   const addUserSignedOut = () => {
//     const userManager = getUserManager();
//     userManager.removeUser();
//     window.location.reload(false);
//   };

//   return <>{children}</>;
// };

export default App;
