export const stringToLocaleDateTimeString = (val) => {
  if (!val) {
    return;
  }

  return new Date(val).toLocaleString();
}

export const stringToLocaleDateString = (val) => {
  if (!val) {
    return;
  }

  return new Date(val).toLocaleDateString();
}