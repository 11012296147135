import classNames from 'classnames';

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  icon: 'icon',
  link: 'link'
};

const SIZES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg'
};

const Button = ({ children, className, variant, size, id, disabled, isUppercase, onClick }) => {
  return (
    <>
      <button
        id={id}
        type="button"
        className={classNames(
          'inline-flex justify-center items-center rounded-md shadow-sm px-4 py-2 h-10 font-medium',
          className,
          variant === VARIANTS.secondary &&
            'border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm',
          variant === VARIANTS.primary &&
            'bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm',
          variant === VARIANTS.icon && 'border-0 shadow-none',
          variant === VARIANTS.link &&
            'text-sm text-indigo-600 hover:text-indigo-500 border-0 shadow-none',
          disabled && 'opacity-50 cursor-not-allowed',
          !size && 'text-base',
          size ? size : 'md',
          isUppercase && 'uppercase '
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

Button.variants = VARIANTS;
Button.sizes = SIZES;

export default Button;
