import { Field, ErrorMessage } from 'formik';

export const FormikCheckbox = ({
  name,
  label,
  secondaryLabel,
  formikProps,
  value,
  onBlur,
  onClick,
  type = null,
  placeholder = null,
  required = false,
  styles
}) => {
  const hasError = formikProps?.errors && formikProps.errors[name];
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500';
  return (
    <div>
      <Field
        id={name}
        type={type || 'checkbox'}
        name={name}
        className={`h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 ${
          styles ? styles : ''
        } ${hasError ? errorClasses : ''}`}
        placeholder={placeholder}
        onBlur={onBlur}
        onClick={onClick}
        value={value}
      />

      <label htmlFor={name} className="text-sm font-medium text-gray-900 cursor-pointer">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>

      {secondaryLabel && (
        <label htmlFor={name} className="mt-2 flex flex-col text-xs	font-medium text-gray-700">
          {secondaryLabel}
        </label>
      )}

      <ErrorMessage name={name} component="div" className="text-red-500" />
    </div>
  );
};
