import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { saveEnvironment } from '../../../../api/environmentsApi';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { Form, Formik } from 'formik';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import { Button } from '../../../../components';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import * as Yup from 'yup';

const formInitialValues = {};

const ManageEnvironment = ({ environment }) => {
    const [formValues, setFormValues] = useState(formInitialValues);
    const updateEnvironmentAction = useToastAction();
    let history = useHistory();
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Field is required'),
        centerPointApiUrl: Yup.string().required('Field is required'),
        identityServerUrl: Yup.string().required('Field is required'),
        licenseValidDue: Yup.number().typeError('Value must be a number').required('Field is required'),
        graceValidDue: Yup.number().typeError('Value must be a number').required('Field is required'),
    });


    const handleOnSubmit = (values, actions) => {
        updateEnvironmentAction.execute(
          async () => {
            await saveEnvironment(values);
            actions.setSubmitting(false);
          },
          'Failed to save data',
          'Save successful'
        );
    };
    
    const handleOnCancel = () => {
        history.push('/');
    };

    useEffect(() => {
        setFormValues(environment);
    }, []);

    return (
        <Formik initialValues={formValues} onSubmit={handleOnSubmit} 
            enableReinitialize={true} validationSchema={validationSchema}>
            {(props) => (
            <Form classNamee="space-y-8 divide-y divide-gray-200 mt-4" >
                <div className="sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Name</label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <FormikInput className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm" name="name" formikProps={props} />
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="centerPointApiUrl" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Center Point Api URL</label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <FormikInput className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm" name="centerPointApiUrl" formikProps={props} />
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="identityServerUrl" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Identity Server URL</label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <FormikInput className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm" name="identityServerUrl" formikProps={props} />
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="licenseValidDue" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">License validity (days)</label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <FormikInput className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm" name="licenseValidDue" formikProps={props} />
                                <p class="mt-2 text-sm text-gray-500">
                                    Number of days that licence will be extended when
                                    new licence file will be requested.
                                </p>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="licenseValidDue" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">License grace (days)</label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <FormikInput className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm" name="graceValidDue" formikProps={props} />
                                <p class="mt-2 text-sm text-gray-500">
                                    Number of days that environment will work 
                                    after licence valid to will pass due
                                </p>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <div>
                                <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">Auto Licence Reneval</div>
                            </div>
                            <div class="mt-4 sm:col-span-2 sm:mt-0">
                                <div class="max-w-lg space-y-4">
                                    <div class="relative flex items-start">
                                        <div class="flex h-5 items-center">
                                            <FormikCheckbox name='autoLicenseReneval' formikProps={props} onClick={props.onChange}/>
                                        </div>
                                        <div class="ml-3 text-sm">
                                            <label for="autoLicenseReneval" class="font-medium text-gray-700">Enable</label>
                                            <p class="text-gray-500">If selected then environment will get a new license after each request</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <Button
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            variant={Button.variants.secondary}
                            onClick={() => handleOnCancel(props.resetForm)}
                            >
                            Cancel
                        </Button>
                        <FormikSubmitButton
                            id="save"
                            text="Save"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            formikProps={props}
                        />
                    </div>
                </div>
            </Form>
            )}
        </Formik>
  );
};

export default ManageEnvironment;
