import React, { useState } from 'react';
import { DocumentReportIcon, DocumentDownloadIcon, DocumentSearchIcon, CashIcon } from '@heroicons/react/outline';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { ReportType, formInitialValues } from '../consts/Reports';
import DatesFromToFilter from '../components/DatesFromToFilter';
import { getReport } from '../api/reportsApi';
import DateTimeParser from '../../../../utils/DateTimeParser';

const EnvironmentReports = ({ environment }) => {
  const [filterDates, setFilterDates] = useState(formInitialValues);
  const fetchReportAction = useToastAction();

  const reports = [
    {
        reportName: 'Customer licences',
        reportDescription: 'Report that contains all environment customer licences for selected period of time',
        icon: <DocumentReportIcon className="mx-auto w-12 h-12 text-green-700" />,
        onDownload: () => handleOnDownload(ReportType.CustomerLicences)
    },
    {
        reportName: 'Transactions',
        reportDescription: 'Report that contains informations about transactions for selected period of time',
        icon: <CashIcon className="mx-auto w-12 h-12 text-yellow-600" />,
        onDownload: () => handleOnDownload(ReportType.Transactions)
    }
  ]

  const handleOnDownload = (reportType) => {
    fetchReportAction.execute(async () => {
        await getReport({
            dateFrom: DateTimeParser.toLocalJSONFormat(DateTimeParser.setSOD(filterDates.dateFrom)),
            dateTo: DateTimeParser.toLocalJSONFormat(DateTimeParser.setEOD(filterDates.dateTo)),
            reportType: reportType,
            environmentId: environment.id
        });
    }, 'Failed to load data');
  }

  return (
    <div>
        <DatesFromToFilter onUpdate={setFilterDates}/>
        <ul role="list" className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {reports.map((report, index) => {
            return (
                <li key={index} className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                    <div className="flex flex-1 flex-col p-8">
                        {report.icon}
                        <h3 className="mt-6 text-sm font-medium text-gray-900">{report.reportName}</h3>
                        <dl className="mt-1 flex flex-grow flex-col justify-between">
                            <dd className="text-sm text-gray-500">{report.reportDescription}</dd>
                        </dl>
                        </div>
                        <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="-ml-px flex w-0 flex-1">
                            <button onClick={report.onDownload} className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                                <DocumentDownloadIcon className="h-5 w-5 text-gray-400" />
                                <span className="ml-3">Download</span>
                            </button>
                            </div>
                        </div>
                    </div>
                </li>
            )})}
        </ul>
    </div>
  );
};

export default EnvironmentReports;
